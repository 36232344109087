@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(custom.css);
.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 3%;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #fff5f5;
}

::-webkit-scrollbar-thumb {
  background: #c7004985;
  /* background: #FFF5F5;  */
}

body {
  font-family: "Questrial", sans-serif;
}

.interFont {
  font-family: "Inter", sans-serif;
}
h1 {
  @apply lg:text-5xl text-3xl font-bold;
}
h2 {
  @apply text-2xl md:text-4xl font-medium uppercase text-[#0C2452];
}
h3 {
  @apply lg:text-3xl text-2xl;
}
h4 {
  @apply lg:text-xl text-lg font-medium;
}

p {
  @apply font-normal text-base sm:text-[16px] interFont;
}

.noUppercase {
  text-transform: none !important;
}
.Toastify__progress-bar-theme--dark {
  background: #3f7fae !important;
}
.pagelayout {
  @apply flex flex-col gap-8 lg:gap-16;
}
